import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import React, { ChangeEvent, Fragment } from 'react';

import InputPercent from '~/components/Inputs/InputPercent2';

import { InpuTypeDate } from '~/components/Pessoa/styles';

import { formatCurrencyAsText } from '~/utils/functions';

import { DataGridComponentProps } from '../../types';
import { InputMoney } from '~/components/NovosInputs';

export const DataGridComponent: React.FC<DataGridComponentProps> = (props) => {
  const {
    loadingItens,
    precos,
    produtosPrecos,
    page,
    rowsPerPage,
    reajusta,
    handleChangeCustoRep,
    handleChangeMargemCalculada,
    handleChangePrecoBase,
    handleChangeDate,
    handleChangePage,
    register,
    control,
    formState: { errors },
    setValue,
  } = props;

  const produtosPrecosArr: any[] = produtosPrecos.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  function formatToCurrency(input: string): string {
    const cleanedInput = input.replace(/\D/g, '');

    if (!cleanedInput || cleanedInput === '0') return '0,00';

    const numberValue = parseInt(cleanedInput, 10);
    const formattedValue = (numberValue / 100).toFixed(2);

    return formattedValue.replace('.', ',');
  }

  return (
    <Paper
      style={{
        width: '100%',
        flex: 1,
        margin: '50px 0',
        zIndex: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LinearProgress
        className={loadingItens ? '' : 'disabled-linear-progress'}
        style={{
          width: '100%',
          top: '118px',
          height: '5px',
          margin: 'auto',
          zIndex: 4,
          background: loadingItens ? '#c2a0f8' : 'transparent',
          color: loadingItens ? '#8850BF' : 'transparent',
        }}
      />
      <TableContainer style={{ maxHeight: 600, zIndex: 4 }}>
        <Table stickyHeader style={{ minHeight: 200 }}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} style={{ zIndex: 2, textAlign: 'center' }}>
                Informações dos Produtos
              </TableCell>

              {precos.map((p: any, index: number) => {
                return (
                  <TableCell
                    key={p.value}
                    colSpan={p.tipo === 4 ? 5 : 4}
                    className={`tipo-${index}`}
                    style={{
                      zIndex: 4,
                      textAlign: 'center',
                      color: '#2b2b2b',
                      borderRight: '1px solid #8850BF',
                    }}
                  >
                    {p.label}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow hover role="checkbox" tabIndex={-1}>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                  minWidth: '100px',
                  background: '#57069e',
                  zIndex: 2,
                  textAlign: 'center',
                }}
              >
                Loja
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                  minWidth: '100px',
                  background: '#57069e',
                  zIndex: 2,
                  textAlign: 'center',
                }}
              >
                Código
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                  minWidth: '200px',
                  background: '#57069e',
                  zIndex: 2,
                  textAlign: 'center',
                }}
              >
                Descrição
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                  zIndex: 2,
                }}
              >
                Emb.
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                }}
              >
                Est Un
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                }}
              >
                Custo Ant.
              </TableCell>
              <TableCell
                style={{
                  position: 'sticky',
                  top: 57,
                }}
              >
                Custo Rep.
              </TableCell>
              {precos.map((p: any, index: number) => (
                <Fragment key={p.value}>
                  <TableCell
                    style={{
                      position: 'sticky',
                      top: 57,

                      fontWeight: 700,
                    }}
                    className={`tipo-${index} MargemRef`}
                  >
                    % Margem Ref.
                  </TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      top: 57,
                      fontWeight: 700,
                    }}
                    className={`tipo-${index}`}
                  >
                    Margem Calc. %
                  </TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      top: 57,
                      fontWeight: 700,
                    }}
                    className={`tipo-${index}`}
                  >
                    Sugestão
                  </TableCell>
                  <TableCell
                    style={{
                      position: 'sticky',
                      top: 57,
                      fontWeight: 700,
                    }}
                    className={`tipo-${index}`}
                  >
                    Preço Base
                  </TableCell>
                  {p.tipo === 4 && (
                    <TableCell
                      style={{
                        position: 'sticky',
                        top: 57,
                        fontWeight: 700,
                      }}
                      className={`tipo-${index}`}
                    >
                      Validade
                    </TableCell>
                  )}
                </Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {produtosPrecosArr.map((prod: any, idx: number) => (
              <TableRow
                key={prod.cod_produto}
                title={`Código do Produto: ${prod.cod_produto} - ${prod.des_produto}`}
              >
                <TableCell
                  style={{
                    textAlign: 'center',
                    position: 'sticky',
                    background: '#fff',
                    minWidth: '100px',
                    zIndex: 2,
                  }}
                >
                  {prod.cod_loja}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                    position: 'sticky',
                    background: '#fff',
                    minWidth: '100px',
                    zIndex: 2,
                  }}
                >
                  {prod.cod_produto}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'center',
                    position: 'sticky',
                    background: '#fff',
                    minWidth: '200px',
                    zIndex: 2,
                  }}
                >
                  {prod.des_produto}
                </TableCell>
                <TableCell>
                  {prod.des_unidade} C/ {prod.qtd_embalagem}
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  {prod.qtd_estoque.toFixed(0)}
                </TableCell>
                <TableCell>
                  {Number.isNaN(prod.val_custo_rep_ant) ||
                  prod.val_custo_rep_ant === undefined
                    ? 'R$ 0,00'
                    : new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(prod.val_custo_rep_ant)}
                </TableCell>
                <TableCell style={{ paddingTop: '4px' }}>
                  <InputMoney
                    label=""
                    style={{
                      minWidth: '70px',
                      width: `${
                        formatCurrencyAsText(prod.val_custo_rep).length * 5 + 70
                      }px`,
                    }}
                    min={0.0}
                    placeholder=""
                    showIcon
                    name={`val_custo_rep_${prod.cod_produto}`}
                    isError={!!errors.val_custo_rep}
                    register={register}
                    control={control}
                    disabled={!prod.flg_emb_principal}
                    onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                      const { value } = ev.target;

                      setValue(
                        `val_custo_rep_${prod.cod_produto}`,
                        formatToCurrency(value),
                      );
                      handleChangeCustoRep(
                        formatToCurrency(value),
                        prod.cod_produto,
                      );
                    }}
                  />
                </TableCell>
                {precos.map((precInput: any) => {
                  const preco = prod.precos.find(
                    (pre: any) =>
                      parseInt(pre.r_cod_preco, 10) ===
                      parseInt(precInput.value, 10),
                  );

                  const parsedPrecoCusto = parseFloat(prod?.val_custo_rep);
                  const parsedPrecoSugestao = parseFloat(preco?.preco_sugestao);

                  const precoSugestao =
                    parsedPrecoSugestao < parsedPrecoCusto
                      ? prod?.val_custo_rep
                      : preco?.preco_sugestao;

                  return (
                    <Fragment key={precInput.value}>
                      <TableCell className="alingLeft">
                        {preco.r_val_margem
                          ? new Intl.NumberFormat('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 3,
                            }).format(preco.r_val_margem)
                          : '0,00'}
                        %
                      </TableCell>
                      <TableCell className="alingLeft">
                        {reajusta ? (
                          <>
                            <div
                              style={{
                                textAlign: 'right',
                                marginBottom: '0.625rem',
                                width: `120px`,
                              }}
                            >
                              <InputPercent
                                value={
                                  preco.margem_calculada === ''
                                    ? '0,00'
                                    : preco.margem_calculada
                                }
                                onChange={(value: number) => {
                                  handleChangeMargemCalculada(
                                    value,
                                    preco.r_cod_preco,
                                    prod.cod_produto,
                                  );
                                }}
                                label=""
                                placeholder=""
                                max={999999.01}
                                min={0.01}
                                casas=".01"
                              />
                            </div>
                          </>
                        ) : (
                          `${preco.margem_calculada}%`
                        )}
                      </TableCell>
                      <TableCell className="alingLeft">
                        {new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(
                          precoSugestao === 'NaN' ? 0.0 : precoSugestao,
                        )}
                      </TableCell>
                      <TableCell style={{ paddingTop: '4px' }}>
                        <InputMoney
                          label=""
                          style={{
                            minWidth: '90px',
                            width: `${
                              formatCurrencyAsText(preco.r_val_preco).length *
                                5 +
                              70
                            }px`,
                          }}
                          min={0.01}
                          max={999999.01}
                          maxLength={10}
                          placeholder=""
                          register={register}
                          control={control}
                          isError={!!errors.val_preco_base}
                          name={`val_preco_base_${prod.cod_produto}_${preco.r_cod_preco}`}
                          onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                            const { value } = ev.target;

                            setValue(
                              `val_preco_base_${prod.cod_produto}_${preco.r_cod_preco}`,
                              formatToCurrency(value),
                            );
                            handleChangePrecoBase(
                              formatToCurrency(value),
                              preco.r_cod_preco,
                              prod.cod_produto,
                            );
                          }}
                        />
                      </TableCell>
                      {precInput.tipo === 4 && (
                        <TableCell>
                          <InpuTypeDate
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              handleChangeDate(
                                e.target.value,
                                preco.r_cod_preco,
                                prod.cod_produto,
                              );
                            }}
                            value={preco.r_dta_validade}
                            type="date"
                            style={{
                              marginTop: '0.0rem',
                              marginBottom: '0.3rem',
                              width: '90%',
                            }}
                          />
                        </TableCell>
                      )}
                    </Fragment>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          {produtosPrecos.length === 0 && (
            <div
              style={{
                left: '0',
                right: '0',
                marginTop: '0px',
                textAlign: 'center',
                position: 'absolute',
              }}
            >
              Nenhum registro encontrado com o critério informado
            </div>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[-1]}
        onPageChange={handleChangePage}
        count={produtosPrecos.length}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Paper>
  );
};
