import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AlteracaoDePrecoPorItemContextData,
  AlteracaoDePrecoPorItemProviderProps,
  CachePesquisaDataProps,
  CachePesquisaInfoProps,
} from '../types/context';
import { useForm } from 'react-hook-form';
import { LojaContext } from '~/context/loja';
import { alteracaoPrecoPorItem } from '../services';
import { PayloadStore, SearchProduto } from '../types';
import { CalculoMargem } from '~/utils/classes/CalculoMargem';
import useAuth from '~/hooks/useAuth';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { nanoid } from 'nanoid';
import { GridRowData, GridRowParams } from '@material-ui/data-grid';
import { convertToDecimal } from '../utils/convertToDecimal';
import { toast } from 'react-toastify';
import { toleraValor } from '../utils/toleraValor';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { formatCurrencyAsText, moneyFormat } from '~/utils/functions';
import { useQueryClient } from 'react-query';
import { loadingContext } from '~/context/loading';

const MySwal = withReactContent(Swal);

export const AlteracaoDePrecoPorItemContext = createContext(
  {} as AlteracaoDePrecoPorItemContextData,
);

export const AlteracaoDePrecoPorItemProvider: React.FC<
  AlteracaoDePrecoPorItemProviderProps
> = ({ children }) => {
  const { changeLoja, loja } = useContext(LojaContext);
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const [loader, setLoader] = useState(false);
  const [codProduto, setCodProduto] = useState('');
  const [productSelected, setProductSelected] = useState<SearchProduto | null>(
    null,
  );
  const [precoArredondamento, setPrecoArredondamento] = useState<any[]>([]);
  const [sugestaoVendaPorMargem, setsugestaoVendaPorMargem] = useState(0);
  const [sugestaoVendaPorCusto, setsugestaoVendaPorCusto] = useState(0);
  const [sugestaoVendaPorMargemFixa, setsugestaoVendaPorMargemFixa] =
    useState(0);
  const [sugestaoVendaPorCustoFixa, setsugestaoVendaPorCustoFixa] = useState(0);
  const [disableCancelButton, setDisableCancelButton] = useState(true);
  const [disableContent, setDisableContent] = useState(true);
  const [detalhesNfFornecedor, setDetalhesNfFornecedor] = useState<
    GridRowData[]
  >([]);
  const [showSearch, setShowSearch] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const { setLoading } = useContext(loadingContext);

  const {
    register,
    control,
    formState,
    setValue,
    reset,
    getValues,
    handleSubmit,
    setFocus,
  } = useForm({
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (user) setPrecoArredondamento(user.precos_arredondamentos);
  }, [user]);

  const onShowSearch = useCallback((show: boolean) => {
    setShowSearch(show);
  }, []);

  const handleChangeProduct = useCallback(
    async (cod_produto: string, cod_loja: number) => {
      const productFound = await alteracaoPrecoPorItem.searchProduto(
        cod_produto,
        cod_loja,
      );

      setCodProduto(cod_produto);

      if (productFound.success && productFound.data) {
        const { data } = productFound;
        const calcMargVda = CalculoMargem.getValMargemVenda(
          data.tipo_margem,
          convertToDecimal(data.val_custo_rep),
          data.val_custo_sem_imposto,
          convertToDecimal(data.val_venda),
          data.val_imposto_debito,
          data.per_desp_op,
        );

        const sugVdaCusto = await CalculoMargem.getValSugestaoVenda(
          data.val_custo_rep,
          data.val_custo_sem_imposto,
          data.val_margem,
          data.per_desp_op,
          data.per_icms_saida,
          data.tipo_margem,
          1,
          data.per_fcp,
          data.per_pis_lj,
          data.per_cofins_lj,
          false,
          precoArredondamento,
        );
        const sugVdaMargem = await CalculoMargem.getValSugestaoVenda(
          data.val_custo_rep,
          data.val_custo_sem_imposto,
          calcMargVda,
          data.per_desp_op,
          data.per_icms_saida,
          data.tipo_margem,
          1,
          data.per_fcp,
          data.per_pis_lj,
          data.per_cofins_lj,
          false,
          precoArredondamento,
        );

        setProductSelected(data);
        setsugestaoVendaPorCusto(Number(sugVdaCusto));
        setsugestaoVendaPorMargem(Number(sugVdaMargem));
        setsugestaoVendaPorCustoFixa(Number(sugVdaCusto));
        setsugestaoVendaPorMargemFixa(Number(sugVdaMargem));

        const detalhesNf = data.detalhes_nf_fornecedor.map((dt) => {
          const dataEntrada = format(new Date(dt.dta_entrada), 'dd/MM/yyyy', {
            locale: ptBR,
          });
          return {
            id: nanoid(),
            apelido_fantasia: dt.apelido_fantasia,
            val_custo_rep: formatCurrencyAsText(dt.val_custo_rep),
            dta_entrada: dataEntrada,
            num_nf: dt.num_nf,
          };
        });

        setDetalhesNfFornecedor(detalhesNf);

        setValue('val_custo_rep', formatCurrencyAsText(data.val_custo_rep));
        setValue('val_margem_calculada', moneyFormat(calcMargVda));
        setValue('val_venda', formatCurrencyAsText(data.val_venda));
      }
    },
    [precoArredondamento, setValue],
  );

  const handleDisableCancelButton = useCallback((value: boolean) => {
    setDisableCancelButton(value);
  }, []);

  const handleDisableContent = useCallback((value: boolean) => {
    setDisableContent(value);
  }, []);

  const handlesugestaoVendaPorMargem = useCallback((value: number) => {
    setsugestaoVendaPorMargem(value);
  }, []);
  const handlesugestaoVendaPorCusto = useCallback((value: number) => {
    setsugestaoVendaPorCusto(value);
  }, []);

  const handleLimparCampos = useCallback(() => {
    setProductSelected(null);
    setCodProduto('');
    setsugestaoVendaPorMargem(0);
    setsugestaoVendaPorCusto(0);
    setsugestaoVendaPorMargemFixa(0);
    setsugestaoVendaPorCustoFixa(0);
    reset({
      busca_produto: '',
      val_custo_rep: '',
      val_margem_calculada: '',
      val_venda: '',
    });
    setDisableCancelButton(true);
    setDisableContent(true);
    setDetalhesNfFornecedor([]);
  }, [reset]);

  const onSave = handleSubmit(async (data) => {
    try {
      setLoading(false);
      if (!productSelected)
        return toast.warning('Busque por um produto antes de gravar');

      const custoRep = convertToDecimal(productSelected.val_custo_rep);
      const novoCustoRep = convertToDecimal(data.val_custo_rep || 0);
      const valVenda = convertToDecimal(productSelected.val_venda);
      const novoValVenda = convertToDecimal(data.val_venda || 0);

      if (loja.regra_empresa.per_bloq_custo > 0) {
        const tolera = toleraValor(
          novoCustoRep,
          custoRep,
          loja.regra_empresa.per_bloq_custo,
        );

        if (!tolera) {
          await MySwal.fire({
            title: 'Custo excedeu o percentual de bloqueio',
            text: 'Regularize a situação para prosseguir',
            icon: 'error',
          });
          return;
        }
      }

      if (loja.regra_empresa.per_bloq_venda > 0) {
        const tolera = toleraValor(
          novoValVenda,
          valVenda,
          loja.regra_empresa.per_bloq_venda,
        );

        if (!tolera) {
          setLoader(false);
          await MySwal.fire({
            title: 'Venda excedeu o percentual de bloqueio',
            text: 'Regularize a situação para prosseguir',
            icon: 'error',
          });
          return;
        }
      }

      if (loja.regra_empresa.per_tol_venda > 0) {
        const tolera = toleraValor(
          novoValVenda,
          valVenda,
          loja.regra_empresa.per_tol_venda,
        );

        if (!tolera) {
          await MySwal.fire({
            title: 'Venda excedeu o percentual de tolerância',
            icon: 'warning',
            confirmButtonText: 'Continuar',
          });
        }
      }

      if (loja.regra_empresa.per_tol_custo > 0) {
        const tolera = toleraValor(
          novoCustoRep,
          custoRep,
          loja.regra_empresa.per_tol_custo,
        );

        if (!tolera) {
          await MySwal.fire({
            title: 'Custo excedeu o percentual de tolerância',
            icon: 'warning',
            confirmButtonText: 'Continuar',
          });
        }
      }

      setLoader(true);
      setLoading(true);

      const payload: PayloadStore = {
        item: productSelected,
        valores: {
          val_custo_rep: novoCustoRep,
          val_venda: novoValVenda,
        },
      };

      const response = await alteracaoPrecoPorItem.store(
        codProduto,
        loja.cod_loja,
        payload,
      );

      if (response.success) {
        handleLimparCampos();
        await onNextItem();
        toast.success(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  });

  const onNew = useCallback(() => {
    handleLimparCampos();
    setShowSearch(false);
    setIsUpdate(false);
  }, [handleLimparCampos]);

  const onCancel = useCallback(() => {
    handleLimparCampos();
    setShowSearch(true);
    setIsUpdate(false);
  }, [handleLimparCampos]);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      const { cod_produto, cod_loja, des_produto } = row;

      await handleChangeProduct(cod_produto, cod_loja);

      setValue('busca_produto', { value: cod_produto, label: des_produto });
      setIsUpdate(true);
      setShowSearch(false);
      setDisableContent(false);
      setDisableCancelButton(false);

      setTimeout(() => {
        changeLoja(cod_loja);
        setFocus('val_custo_rep', { shouldSelect: true });
      }, 500);
    },
    [changeLoja, handleChangeProduct, setFocus, setValue],
  );

  const onNextItem = useCallback(async () => {
    const cachePesquisaInfo = queryClient.getQueryData<CachePesquisaInfoProps>(
      'cacheLastSearch_290',
    );

    if (!cachePesquisaInfo) return;

    const cachePesquisaData = queryClient.getQueryData<CachePesquisaDataProps>([
      'cache_tela_290',
      cachePesquisaInfo.pag,
    ]);

    if (!cachePesquisaData) return;

    const currentItemIdx = cachePesquisaData.data.findIndex(
      (item) => item.cod_produto === codProduto,
    );

    if (currentItemIdx === -1) return;

    const nextItemIdx = currentItemIdx + 1;

    let dataToRowOnClick: any = {
      row: cachePesquisaData.data[nextItemIdx],
    };

    if (!cachePesquisaData.data[nextItemIdx]) {
      if (cachePesquisaData.data.length - 1 > 0) {
        dataToRowOnClick = { row: cachePesquisaData.data[0] };
      } else {
        setTimeout(() => {
          onCancel();
        }, 500);

        return;
      }
    }

    await onRowClick(dataToRowOnClick);
  }, [codProduto, onCancel, onRowClick, queryClient]);

  return (
    <AlteracaoDePrecoPorItemContext.Provider
      value={{
        loader,
        productSelected,
        codProduto,
        sugestaoVendaPorMargem,
        sugestaoVendaPorCusto,
        sugestaoVendaPorCustoFixa,
        sugestaoVendaPorMargemFixa,
        disableCancelButton,
        detalhesNfFornecedor,
        disableContent,
        precoArredondamento,
        showSearch,
        isUpdate,

        register,
        control,
        formState,
        setValue,
        getValues,

        changeLoja,
        handleChangeProduct,
        handleLimparCampos,
        handleDisableCancelButton,
        handleDisableContent,
        handlesugestaoVendaPorMargem,
        handlesugestaoVendaPorCusto,
        onSave,
        onShowSearch,
        onNew,
        onCancel,
        onRowClick,
      }}
    >
      {children}
    </AlteracaoDePrecoPorItemContext.Provider>
  );
};
