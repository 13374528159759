import api from '~/services/api';
import { PayloadStore, ResponseSearchProduto, ResponseStore } from '../types';

const searchProduto = async (
  cod_produto: string,
  cod_loja: number,
): Promise<ResponseSearchProduto> => {
  const { data } = await api.get<ResponseSearchProduto>(
    `/alteracao-preco-item/produto/${cod_produto}/${cod_loja}`,
  );
  return data;
};

const store = async (
  cod_produto: string,
  cod_loja: number,
  payload: PayloadStore,
): Promise<ResponseStore> => {
  const { data } = await api.post<ResponseStore>(
    `/alteracao-preco-item/store/${cod_produto}/${cod_loja}`,
    payload,
  );
  return data;
};

export const alteracaoPrecoPorItem = { searchProduto, store };
