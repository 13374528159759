export const formatToCurrency = (input: string | number): string => {
  if (typeof input === 'number') input = input.toString();

  const cleanedInput = input.replace(/\D/g, '');

  if (!cleanedInput || cleanedInput === '0') return '0,00';

  const numberValue = parseInt(cleanedInput, 10);
  const formattedValue = (numberValue / 100).toFixed(2);

  return formattedValue.replace('.', ',');
};
