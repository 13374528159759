import { GridColumns } from '@material-ui/data-grid';

export const colunasUltEntrada: GridColumns = [
  {
    field: 'id',
    headerName: 'Id',
    hide: true,
    flex: 1,
    sortable: false,
  },
  {
    field: 'apelido_fantasia',
    headerName: 'Fornecedor',
    hide: false,
    width: 400,
    sortable: false,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'val_custo_rep',
    headerName: 'Custo NF',
    width: 150,
    hide: false,
    sortable: false,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'dta_entrada',
    headerName: 'Data',
    width: 150,
    hide: false,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'num_nf',
    headerName: 'Núm. NF',
    width: 130,
    hide: false,
    sortable: false,
    headerAlign: 'right',
    align: 'right',
    headerClassName: 'hideRightSeparator',
  },
];
