import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  height: 100%;
  padding: 1.875rem;

  .content-buscaproduto {
    display: flex;
    align-items: center;
    gap: 0.8125rem;
    width: 100%;
  }

  .buttonCancel {
    background-color: #cc0000;
    margin-top: 1.875rem;
    border: none;
    padding: 0.5rem;
    width: 8.125rem;
    color: white;
    border-radius: 0.25rem;

    &:hover {
      opacity: 80%;
    }

    &:disabled {
      opacity: 60%;
      cursor: not-allowed;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 25% 72.9%;

    gap: 1.5625rem;

    @media only screen and (max-width: 1000px) {
      display: flex;
      flex-direction: column;
    }
  }

  .myDataGrid .hideRightSeparator .MuiDataGrid-columnSeparator {
    display: none;
  }
`;

export const ContainerLoader = styled.div`
  width: 100%;
  height: 4.6875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
