import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    color: #878787;
  }

  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  svg {
    height: 1.6875rem;
  }
`;
