/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { transformAsCurrency } from '~/utils/functions';

const variacao = (value_1: any, value_2: any) => {
  const sub = transformAsCurrency(value_1) - value_2;
  const result = (sub * 100) / value_2;
  return result;
};

export const toleraValor = (
  value: number,
  valueAnt: number,
  perTolerancia: any,
): boolean => {
  if (value > 0 && valueAnt > 0) {
    const sum = variacao(value, valueAnt);
    if (sum > perTolerancia) {
      return false;
    }
    return true;
  }
  return true;
};
