import React from 'react';
import { Table } from './styles';
import { SearchProduto } from '../../types';
import { formatCurrencyAsText } from '~/utils/functions';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { HistoricoProduto } from '~/components/HistoricoProduto';

interface CustomTableProps {
  item: SearchProduto | null;
  cod_loja: number;
  cod_produto: string;
  sugestaoVendaPorMargem: number;
  disableContent: boolean;
}

const thArray = [
  { label: 'Código', align: 'left' },
  { label: 'Custo Anterior', align: 'left' },
  { label: 'Venda Anterior', align: 'left' },
  { label: 'Emb', align: 'left' },
  { label: 'Estoque', align: 'left' },
  { label: '% Margem Ref', align: 'left' },
  { label: 'Sugestão', align: 'left' },
  { label: 'Histórico', align: 'center' },
];

export const ItemTable: React.FC<CustomTableProps> = ({
  item,
  cod_loja,
  cod_produto,
  sugestaoVendaPorMargem,
  disableContent,
}) => {
  const formataData = (dt: string | null | undefined) => {
    if (!dt) return '';

    const data = new Date(dt);
    const dataFormatada = format(data, 'dd/MM/yyyy', { locale: ptBR });

    return dataFormatada;
  };

  const arr = disableContent ? thArray.slice(0, -1) : thArray;

  return (
    <Table>
      <thead>
        <tr>
          {arr.map((column) => (
            <th key={column.label} className={`align-${column.align}`}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{cod_produto}</td>
          <td>
            {!disableContent && (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <span>
                  R$ {formatCurrencyAsText(item?.val_custo_rep_ant || 0)}
                </span>
                <span>{formataData(item?.dta_ult_alt_custo_rep)}</span>
              </span>
            )}
            {disableContent && <>R$ 0,00</>}
          </td>
          <td>
            <span
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <span>
                R${' '}
                {disableContent
                  ? '0,00'
                  : formatCurrencyAsText(item?.val_preco_base_ant)}
              </span>
              <span>{formataData(item?.dta_ult_alt_preco_base)}</span>
            </span>
          </td>
          <td>
            {!disableContent && (
              <>
                {item?.des_unidade} C/{item?.qtd_emb_principal}
              </>
            )}
          </td>
          <td>{formatCurrencyAsText(item?.qtd_estoque)}</td>
          <td>{formatCurrencyAsText(item?.val_margem)}</td>
          <td>R$ {formatCurrencyAsText(sugestaoVendaPorMargem)}</td>
          {!disableContent && (
            <td
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <HistoricoProduto
                codLoja={cod_loja}
                codProduto={cod_produto}
                hasIcone
              />
            </td>
          )}
        </tr>
      </tbody>
    </Table>
  );
};
