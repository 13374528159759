import React, { ChangeEvent } from 'react';
import { InputMoney, InputPercent } from '~/components/NovosInputs';
import { useAlteracaoDePrecoPorItem } from '../../hooks/useAlteracaoDePrecoPorItem';
import { formatToCurrency } from '../../utils/formatToCurrency';
import { InputsContent } from './styles';
import { CalculoMargem } from '~/utils/classes/CalculoMargem';
import { convertToDecimal } from '../../utils/convertToDecimal';
import { formatCurrencyAsText, moneyFormat } from '~/utils/functions';
import { IoMdInformationCircleOutline } from 'react-icons/io';

export const ScreenFields: React.FC = () => {
  const {
    register,
    control,
    formState,
    setValue,
    getValues,
    disableContent,
    productSelected,
    handlesugestaoVendaPorMargem,
    sugestaoVendaPorMargem,
    handlesugestaoVendaPorCusto,
    sugestaoVendaPorCusto,
    precoArredondamento,
  } = useAlteracaoDePrecoPorItem();

  return (
    <InputsContent>
      <InputMoney
        label="Custo Reposição"
        min={0.0}
        max={99999}
        maxLength={8}
        placeholder="0,00"
        showIcon
        name="val_custo_rep"
        isError={!!formState.errors.val_custo_rep}
        register={register}
        disabled={disableContent}
        control={control}
        onInput={async (ev: ChangeEvent<HTMLInputElement>) => {
          const { value } = ev.target;
          const val = formatToCurrency(value);

          if (productSelected) {
            const calcMargVda = CalculoMargem.getValMargemVenda(
              productSelected.tipo_margem,
              convertToDecimal(val),
              productSelected.val_custo_sem_imposto,
              convertToDecimal(getValues('val_venda')),
              productSelected.val_imposto_debito,
              productSelected.per_desp_op,
            );
            setValue('val_margem_calculada', moneyFormat(calcMargVda));

            if (calcMargVda) {
              const calcSugVda = await CalculoMargem.getValSugestaoVenda(
                convertToDecimal(val),
                productSelected.val_custo_sem_imposto,
                productSelected.val_margem,
                productSelected.per_desp_op,
                productSelected.per_icms_saida,
                productSelected.tipo_margem,
                1,
                productSelected.per_fcp,
                productSelected.per_pis_lj,
                productSelected.per_cofins_lj,
                false,
                precoArredondamento,
              );
              handlesugestaoVendaPorCusto(Number(calcSugVda));
            }
          }

          setValue('val_custo_rep', val);
        }}
      />
      <p
        style={{ color: 'red', marginTop: '3px' }}
        title="Calculado sobre o % de margem referência atual do produto, baseado no custo informado neste campo."
      >
        Sugestão por Custo: R$ {formatCurrencyAsText(sugestaoVendaPorCusto)}{' '}
        <IoMdInformationCircleOutline size={18} />
      </p>
      <InputPercent
        label="Margem Calculada"
        min={0.0}
        max={99999}
        maxLength={8}
        placeholder="0,00"
        showIcon
        name="val_margem_calculada"
        isError={!!formState.errors.val_margem_calculada}
        register={register}
        disabled={disableContent}
        control={control}
        onInput={async (ev: ChangeEvent<HTMLInputElement>) => {
          const { value } = ev.target;
          const val = formatToCurrency(value);

          if (productSelected) {
            const calcSugVda = await CalculoMargem.getValSugestaoVenda(
              convertToDecimal(getValues('val_custo_rep')),
              productSelected.val_custo_sem_imposto,
              convertToDecimal(val),
              productSelected.per_desp_op,
              productSelected.per_icms_saida,
              productSelected.tipo_margem,
              1,
              productSelected.per_fcp,
              productSelected.per_pis_lj,
              productSelected.per_cofins_lj,
              false,
              precoArredondamento,
            );

            handlesugestaoVendaPorMargem(Number(calcSugVda));
          }

          setValue('val_margem_calculada', val);
        }}
      />
      <p
        style={{ color: 'red', marginTop: '3px' }}
        title="Calculado sobre o % de margem informada neste campo, para que você faça simulações."
      >
        Sugestão por Margem: R$ {formatCurrencyAsText(sugestaoVendaPorMargem)}{' '}
        <IoMdInformationCircleOutline size={18} />
      </p>
      <InputMoney
        label="Venda"
        min={0.0}
        max={99999}
        maxLength={8}
        placeholder="0,00"
        showIcon
        name="val_venda"
        isError={!!formState.errors.val_venda}
        register={register}
        disabled={disableContent}
        control={control}
        onInput={async (ev: ChangeEvent<HTMLInputElement>) => {
          const { value } = ev.target;
          const val = formatToCurrency(value);

          if (productSelected) {
            const calcMargVda = CalculoMargem.getValMargemVenda(
              productSelected.tipo_margem,
              convertToDecimal(getValues('val_custo_rep')),
              productSelected.val_custo_sem_imposto,
              convertToDecimal(val),
              productSelected.val_imposto_debito,
              productSelected.per_desp_op,
            );

            setValue('val_margem_calculada', moneyFormat(calcMargVda));

            if (calcMargVda) {
              const calcSugVda = await CalculoMargem.getValSugestaoVenda(
                convertToDecimal(getValues('val_custo_rep')),
                productSelected.val_custo_sem_imposto,
                productSelected.val_margem,
                productSelected.per_desp_op,
                productSelected.per_icms_saida,
                productSelected.tipo_margem,
                1,
                productSelected.per_fcp,
                productSelected.per_pis_lj,
                productSelected.per_cofins_lj,
                false,
                precoArredondamento,
              );

              handlesugestaoVendaPorCusto(Number(calcSugVda));
            }
          }

          setValue('val_venda', val);
        }}
      />
    </InputsContent>
  );
};
