import styled from 'styled-components';

export const InputsContent = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 18rem;

    @media only screen and (max-width: 1399px) {
      width: 15.25rem;
    }

    @media only screen and (max-width: 1199px) {
      width: 12.4375rem;
    }

    @media only screen and (max-width: 1000px) {
      width: 49.75rem;
    }

    @media only screen and (max-width: 991px) {
      width: 34.75rem;
    }

    @media only screen and (max-width: 767px) {
      width: 23.5rem;
    }
  }
`;
