import React from 'react';
import { AlteracaoDePrecoPorItemContent } from './AlteracaoDePrecoPorItemContent';
import { AlteracaoDePrecoPorItemProvider } from './contexts/AlteracaoDePrecoPorItemContext';

export const AlteracaoDePrecoPorItem: React.FC = () => {
  return (
    <AlteracaoDePrecoPorItemProvider>
      <AlteracaoDePrecoPorItemContent />
    </AlteracaoDePrecoPorItemProvider>
  );
};
